<template>
  <div class="thirdsdk">
    <Head />
    <TitleBg :flag="showTitle" />
    <div class="main">
      <div class="doing-what">第三方SDK目录</div>
      <div class="help-content">
        <div class="help-box">
          <ul>
            <li>
              <div class="bottom problem">
                <div class="p_content">
                  <p class="p_title title" style="text-align: center">第三方SDK目录</p>
                  <p class="p_icon">华为渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518"
                  >https://developer.huawei.com/consumer/cn/doc/development/AppGallery-connect-Library/agc-auth-android-sdkdownload-0000001058498518</a>
                  </p>
                  <p class="p_icon"><strong>&nbsp;</strong></p>
                  <p class="p_icon">vivo渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://dev.vivo.com.cn/documentCenter/doc/6"
                  >https://dev.vivo.com.cn/documentCenter/doc/6</a>
                  </p>
                  <p class="p_icon"><strong>&nbsp;</strong></p>
                  <p class="p_icon">百度渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://g.baidu.com/gpbusiness/#/index"
                  >https://g.baidu.com/gpbusiness/#/index</a>
                  </p>

                  <p class="p_icon"><strong>&nbsp;</strong></p>
                  <p class="p_icon">应用宝渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能、使用分享功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD"
                  >https://wiki.open.qq.com/index.php?title=YSDK%E4%B8%8B%E8%BD%BD</a>
                  </p>
                  <p class="p_icon"><strong>&nbsp;</strong></p>
                  <p class="p_icon">小米渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://dev.mi.com/console/doc/detail?pId=102"
                  >https://dev.mi.com/console/doc/detail?pId=102</a>
                  </p>

                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">魅族渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C"
                  >http://open-wiki.flyme.cn/doc-wiki/index?title=SDK%E6%8E%A5%E5%85%A5%E6%89%8B%E5%86%8C</a>
                  </p>

                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">OPPO渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://open.oppomobile.com/wiki/doc#id=10470"
                  >https://open.oppomobile.com/wiki/doc#id=10470</a>
                  </p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">联想渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a href="http://open.lenovomm.com/">http://open.lenovomm.com/</a>
                  </p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">九游渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://aligames.open.uc.cn/document/doc/detail/15"
                  >https://aligames.open.uc.cn/document/doc/detail/15</a>
                  </p>
                  <p class="p_icon"><strong>&nbsp;</strong></p>
                  <p class="p_icon">手Q渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://game.qq.com/contract.shtml"
                  >https://game.qq.com/contract.shtml</a></p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">微信渠道SDK</p>
                  <p class="p_icon">使用目的：接入账号系统和使用支付功能，分享功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml"
                  >https://pay.weixin.qq.com/wiki/doc/apiv3/wxpay/pages/index.shtml</a>
                  </p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">支付宝支付SDK</p>
                  <p class="p_icon">使用目的：使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://opendocs.alipay.com/open/204"
                  >https://opendocs.alipay.com/open/204</a></p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">支付宝h5支付SDK</p>
                  <p class="p_icon">使用目的：使用支付功能</p>
                  <p class="p_icon">官网链接：<a href="http://myjsapi.alipay.com/jsapi/">http://myjsapi.alipay.com/jsapi/</a>
                  </p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">QQ分享SDK</p>
                  <p class="p_icon">使用目的：使用分享功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd"
                  >https://wiki.connect.qq.com/sdk%e4%b8%8b%e8%bd%bd</a>
                  </p>

                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">神州付SDK</p>
                  <p class="p_icon">使用目的：使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://cardmaster.shenzhoufu.com/"
                  >https://cardmaster.shenzhoufu.com/</a></p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">udesk客服SDK</p>
                  <p class="p_icon">使用目的：收集玩家问题</p>
                  <p class="p_icon">官网链接：<a
                    href="https://www.udesk.cn/apply-o2o.html"
                  >https://www.udesk.cn/apply-o2o.html</a></p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">极验一键登录SDK</p>
                  <p class="p_icon">使用目的：方便玩家登录</p>
                  <p class="p_icon">官网链接：<a href="https://www.geetest.com/">https://www.geetest.com/</a></p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">Bugly异常信息收集SDK</p>
                  <p class="p_icon">使用目的：收集APP崩溃信息</p>
                  <p class="p_icon">官网链接：<a href="https://bugly.qq.com/v2/downloads">https://bugly.qq.com/v2/downloads</a>
                  </p>
                  <p class="p_icon">&nbsp;</p>
                  <p class="p_icon">银联支付SDK</p>
                  <p class="p_icon">使用目的：使用支付功能</p>
                  <p class="p_icon">官网链接：<a
                    href="https://cn.unionpay.com/upowhtml/cn/templates/index/index.html"
                  >https://cn.unionpay.com/upowhtml/cn/templates/index/index.html</a>
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <Floor />
  </div>
</template>
<script>
import Head from '@/views/Head'
import Floor from '@/views/Floor'
import TitleBg from '@/views/NewsDetails/TitleBg'

export default ({
  name: "ThirdSDK",
  components: {
    Head,
    Floor,
    TitleBg
  },
  data() {
    return {
      showTitle: false
    }
  }
})
</script>

<style lang="scss" scoped>
* {
  padding: 0;
  margin: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: #3a79d4;
}

img {
  vertical-align: middle;
}

p {
  margin-bottom: 0;
}

html,
body {
  background-color: #f6f7fb;
  font-family: "Microsoft Yahei" !important;
}
body {
  display: none;
}
/* ::-webkit-scrollbar {
  display: none;
  } */
body {
  background-color: #fff;
  /* font-size: 1*2rem; */
}

.help {
  color: #e62631 !important;
}

.big_logo .bgi {
  width: 100%;
}

.doing-what {
  border-left: 0.125*2rem solid #3a79d4;
  font-size: 0.6875*2rem;
  padding-left: 0.6125*2rem;
  line-height: 0.6875*2rem;
  margin: 0.625*2rem 10.3125*2rem;
}

.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.help-content .help-box {
  margin: 1.25*2rem 0;
  overflow: hidden;
  width: 35.625*2rem;
  background-color: #fff;
  padding: 0.8125*2rem;
  box-sizing: border-box;
  border-radius: 0.1875*2rem;
  border: 0.0625*2rem solid #eee;
}

.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  font-size: 0.5625*2rem;
  margin-bottom: 0.625*2rem;
}

.help-content .help-box li .top {
  width: 33.6875*2rem;
  height: 2.0625*2rem;
  padding: 0 1.25*2rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .jiantou {
  width: 0.3125*2rem;
  height: 0.3125*2rem;
  border-left: 0.0625*2rem solid #ccc;
  border-bottom: 0.0625*2rem solid #ccc;
  transform: rotate(-45deg);
}

.help-content .help-box li:last-of-type .jiantou {
  transform: rotate(-45deg);
}

.help-content .help-box li .problem {
  padding: 0 1.25*2rem 0.625*2rem 1.25*2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .topic {
  color: red;
  margin-top: 0.625*2rem;
}

.help-content .help-box li .problem .content {
  padding: 0.625*2rem;
  margin-top: 0.625*2rem;
  background-color: #f5f5f5;
  border-radius: 0.1875*2rem;
  border: 0.0325*2rem solid #e7e7e7;
  font-size: 0.4375*2rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .problem .content span:not(:last-of-type) {
  height: 1.25*2rem;
}

.help-content .help-box li .bottom {
  border-top: 0.0325*2rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.problem .bottomic {
  display: none;
}

.p_con,
.p_join {
  line-height: 1.25*2rem;
  font-size: 0.5*2rem;
  text-align: center;
}

.p_content .p_title {
  font-size: 0.5587*2rem;
  font-weight: 700;
  line-height: 1.5*2rem;
}

.p_content .p_line {
  font-size: 1*2rem;
  width: 0.625*2rem;
  color: #c4222e;
}

.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2*2rem;
  font-size: 0.4375*2rem;
}

.p_content .min_title {
  font-size: 0.4688*2rem;
  font-weight: 700;
}

.left {
  float: left;
}

.media {
  margin-left: 3.125*2rem;
  margin-top: 1.25*2rem;
}

.p_content img {
  width: 100%;
}
</style>
